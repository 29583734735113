<template>
  <div>
    <h1 class="uk-text-left uk-margin-small-top"><a @click="goBack()" style="text-decoration: none;" class="uk-flex-auto"><span uk-icon="icon: chevron-left; ratio: 2.2"></span><span>{{ $t('Pages.UserProfile.Title') }}</span></a></h1>
    <hr />

    <div class="uk-grid-divider" uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-container uk-margin-large-top">
          <form class="uk-form-horizontal uk-margin-large uk-text-left" @submit.prevent="updateUserInfo();">
            <div class="uk-margin-small">
              <label class="uk-form-label" for="form-horizontal-text">{{ $t('Pages.UserProfile.Id') }}</label>
              <div class="uk-form-controls">
                <input class="uk-input" type="text" placeholder="Id" :value="user.id" disabled>
              </div>
            </div>
            <div class="uk-margin-small">
              <label class="uk-form-label" for="form-horizontal-text">{{ $t('Generic.Labels.Email') }}</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <input disabled class="uk-input" :class="{'invalid-border': errors.email}" type="email" :placeholder="$t('Generic.Labels.Email')" v-model="form.user.email" @blur="validateOnBlur('email')" @focus="errors.email = false">
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.email" uk-icon="icon: warning" ></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.email" >{{errors.email}}</span>
                </div>
              </div>
            </div>
            <div class="uk-margin-small">
              <label class="uk-form-label" for="form-horizontal-text">{{ $t('Pages.UserProfile.Status') }}</label>
              <div class="uk-form-controls">
                <input disabled class="uk-input" :value="form.user.active ? $t('Generic.Labels.Active') : $t('Generic.Labels.Inactive')" />
              </div>
            </div>
            <div class="uk-margin-small">
              <label class="uk-form-label" for="form-horizontal-text">{{ $t('Generic.Labels.FirstName') }}
                <span class="uk-text-danger">*</span>
              </label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <input class="uk-input" :class="{ 'invalid-border': errors.firstName }" type="text" :placeholder="$t('Generic.Labels.FirstName')" v-model.trim="form.user.firstName" @blur="validateOnBlur('firstName')" @focus="errors.firstName = false">
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.firstName" uk-icon="icon: warning" ></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.firstName" >{{errors.firstName}}</span>
                </div>
              </div>
            </div>
            <div class="uk-margin-small">
              <label class="uk-form-label" for="form-horizontal-text">{{ $t('Generic.Labels.LastName') }}
                <span class="uk-text-danger">*</span>
              </label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <input class="uk-input" :class="{ 'invalid-border': errors.lastName }" type="text" :placeholder="$t('Generic.Labels.LastName')" v-model.trim="form.user.lastName" @focus="errors.lastName = false" @blur="validateOnBlur('lastName')">
                  <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.lastName" uk-icon="icon: warning" ></span>
                  <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.lastName" >{{errors.lastName}}</span>
                </div>
              </div>
            </div>
            <div>
              <ChangePasswordModal />
            </div>
            <hr />
            <button v-if="!saving" class="uk-button uk-button-primary uk-align-center" @click.prevent="updateUserInfo();">{{ $t('Actions.Save') }}</button>
            <button v-else class="uk-button uk-button-primary uk-align-center" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
          </form>
        </div>
      </div>
  
      <div class="uk-width-1-3">
        <h3>{{ $t('Pages.UserProfile.Organizations') }}</h3>
        <div class="uk-container">
          <div class="uk-tile uk-tile-primary uk-border-rounded uk-padding-small">
            <div class="uk-tite-title">{{ $t('Pages.UserProfile.MemberOf') }}</div>
            <div class="uk-tite-body"><router-link :to="{ name: 'Organizations' }">{{ organizationNumber}} {{ $t('Pages.UserProfile.Organizations') }}</router-link></div>
          </div> 
        </div>
        <UserGravatar class="uk-margin-medium-top" size="120" :email="form.user.email"/>
        <h4 class="uk-text-center uk-margin-small-top">{{username}}</h4>
        <br>
        <button class="uk-button uk-button-primary rounded-border" uk-toggle="target: #change-password-modal-false" @click.prevent="">{{ $t('Pages.UserProfile.ChangePassword') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Notification from '@/components/util/Notification'
import ChangePasswordModal from '@/components/modals/ChangePasswordModal'
import UserGravatar from '@/components/images/UserGravatar'
import { deepClone } from '@/utils/jsonHandler'

export default {
  name: 'UserProfile',
  components: {
    ChangePasswordModal,
    UserGravatar
  },
  data() {
    return {
      saving: false,
      form : {
        user: {
          firstName: '',
          lastName: '',
          screenName: '',
          email: '',
          active: true
        }
      },
      errors: {
        firstName: '',
        lastName: '',
        screenName: '',
        email: ''
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.venom.user || {}
    }),
    username () {
      return `${this.form.user.firstName} ${this.form.user.lastName}`
    },
     organizationNumber(){
      return this.user.organizations?.length || 0;
    },
    validateEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.form.user.email).toLowerCase());
    }
  },
  beforeDestroy () {
    const modalElement = document.getElementById('change-password-modal-false')
    if (modalElement) {
      window.UIkit.modal('#change-password-modal-false').$destroy(true);
    }
  },
  methods: {
    async updateUserInfo() {
      const { firstName, lastName } = this.form.user;
      if (!this.validateForm()) return;
      this.saving = true;
      const params = {
        firstName: this.form.user.firstName,
        lastName: this.form.user.lastName,
        email: this.form.user.email
      }
      const response = await this.$store.dispatch('updateUserInfo', params);
      if (response.id) {
        Notification.showNotification(this.$t('Generic.Messages.Successful'), this.$t('Actions.Updated') + `${firstName} ${lastName}` );
      } else {
        Notification.showNotification(this.$t('Generic.Messages.Unsuccessful'), response, 'error')
      }
      this.saving = false;
    },
    validateOnBlur (field) {
      if (field === 'email') {
        if (!this.form.user.email) {
          this.errors[field] = this.$t('Generic.Errors.MandatoryField')
        }
        this.form.user.email = this.form.user?.email?  this.form.user.email.replace(/ /g, '').toLowerCase():"";
        if (this.validateEmail) {
          this.errors.email = false
        } else {
          this.errors.email = this.$t('Generic.Errors.InvalidEmail')
        }
        return this.errors[field]
      }
      if (!this.form.user[field] || this.form.user[field].length < 2) {
        this.errors[field] = this.$t('Generic.Errors.Min2Chars')
      } else {
        this.errors[field] = false
      }
    },
    validateForm () {
      this.validateOnBlur('firstName')
      this.validateOnBlur('lastName')
      this.validateOnBlur('email')
      this.validateOnBlur('screenName')
      let isValid = true
      if (this.errors.firstName || this.errors.lastName || this.errors.email || this.errors.screenName) isValid = false
      return isValid
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.form.user = deepClone(this.user);
  }
}
</script>

<style scoped>
.rounded-border {
  border-radius: 32px;
}
</style>
