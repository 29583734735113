const deepClone = (obj) => {
  if (!obj) return {}
  const clone = {}
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      clone[key] = []
      for (let i = 0; i < obj[key].length; i++) {
        if (typeof obj[key][i] === 'object') {
          clone[key][i] = deepClone(obj[key][i])
        } else {
          clone[key][i] = obj[key][i]
        }
      }
    } else if (typeof obj[key] === 'object') {
      clone[key] = deepClone(obj[key])
    } else {
      clone[key] = obj[key]
    }
  }
  return clone
}

module.exports = {
  deepClone
}
